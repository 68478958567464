body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#load{
  width:100%;
  left: 0;
  height:100%;
  position:fixed;
  z-index:9999;
  background:url("/ajax-loader.gif") no-repeat center center rgba(255,255,255,0.6)
}
.show{display: block;}
.hide{display: none;}
.productWidth{width:100%}
.categoryWidth{width:430px!important}
.int_darkab_service_section {
  background-color: #282d32;
  /* padding: 77px 0 50px; */
}
.int_darkab_service_section .int_service_box2
{
  border: solid 1px #212529;
    background-color: #212529
}
.int_service_box2 {
  text-align: center;
  padding: 20px;
  border: solid 1px #efefef;
  margin-bottom: 30px;
  margin-top: 30px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.int_darkab_service_section .int_service_box2 h4 {
  color: #ffffff;
}
.int_client_slider {
  background-color: #191b2e;
  padding: 60px 0;
  text-align: center;
}
.int_subscribe_style4 {
  background-color: #222222;
  padding: 73px 0;
}
.int_textbox_subscribe4 .int_search_wrapper {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
}
.int_textbox_subscribe4 {
  position: relative;
}
.int_info_four h2 {
  color: #ffffff;
}
.int_textbox_subscribe4 .int_search_wrapper p {
  font-size: 26px;
  color: #ffffff;
}
.int_about_wrapper {
  padding-top: 80px;
  /* padding-bottom: 80px; */
  background-color: #222222;
}
.int_about_wrapper_new
{
  background-color: #fff!important;
}
.int_heading h4 {
  color: #c6a47e;
  font-size: 45px;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  z-index: 1;
}
.about_box_wrapper .about_imgbox {
  text-align: right;
}
.about_imgbox .about_img {
  position: relative;
}
.about_imgbox .about_img .child_imgbox {
  position: absolute;
  bottom: -70px;
  left: 20px;
}
.about_contentbox {
  padding-top: 20px;
}
.about_contentbox .int_heading {
  margin-bottom: 0;
  text-align: left;
}
.about_contentbox .about_deatils {
  color: #cdcdcd;
  padding-top: 15px;
  text-align: justify;
  font-size: 16px;
}


.about_contentbox .int_btn {
    margin-top: 15px;
}
.about_box_wrapper .int_btn, .about_box_wrapper .int_btn:focus {
    min-width: 150px;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    outline: none;
    border: none;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    color: #f00;
    background: #c6a47e;
    position: relative;
    z-index: 1;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
}
.footer-category li {
  width: 210px!important;
  float: left;
}
.red-text{color: red;}
.green-text{color: green;}
.img-fluid1{width: 100px; text-align: center;}
.txt-center{text-align: center;}
.img-holder{width: 100%;}
.int_map_wrapper {
  display: grid;
}
.welcome{float: left;font-weight: bold;margin-top: 10px;}